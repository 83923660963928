.Page {
  background-color: var(--accent-greige);

}

h1 {
  font-family: 'Nunito', sans-serif;  
  font-size: 40px;
  font-weight: 500;
}

h2 {
  font-family: 'Nunito', sans-serif;  
  font-size: 36px;
  font-weight: 500;
}

h3 {
  font-family: 'Nunito', sans-serif;  
  font-size: 28px;
  font-weight: 500;
  margin: 0px;
}

p {
  font-family: 'Nunito', sans-serif;  
  font-size: 20px;
}

a {
  color: black;
  text-decoration: none;
}

.FrontPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.small-button {
  background-color: #5f5f5f;
  color: var(--accent-greige);
  border-radius: 2px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

img{
  max-width: 100%;
}

.front-page-row {
  width: 90%;
  flex-wrap: wrap;
}

.front-opening-hours, .booking-module {
  margin: 3%;
  width: min(100%, 400px);
}

.front-opening-hours {
  flex-direction: column !important;
}

.openingHoursContainer {
  margin-top: 1%;
  width: 100%;
  justify-content: space-around !important;
}

.booking-module-iframe {
  width: 100%;
  height: 450px;
}

.navContainer {
  margin-top: 3%;
  width: min(90%, 1250px);
  justify-content: space-around !important;
}


.navItem {
  /* background-color: var(--accent-greige);
  width: 30%;
  height: 100px;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 50%; */
  width: 30%;
}

.nav-button {
  position: relative;
  text-align: center;
}

.nav-button img {
  opacity: 0.5;
}

.nav-button p {
  font-size: 3.5vw !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav-image {
  width: 100%;
}

.nav-button:hover img {
  opacity: 1;
}

.navItem:hover {
  transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.oval-shape {
  width: 200px;
  height: 100px;
  -moz-border-radius: 100px / 50px;
  -webkit-border-radius: 100px / 50px;
  border-radius: 100px / 50px;
}


Link {
  color: black;
}

.openingHoursOverview {
  width: 100%;
}



.Footer {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6% 0% 4% 0%;
  background-color: var(--accent-greige);
}

.footer-logo {
  width: min(80%, 300px);
}

.footer-info div {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.Footer div p {
  
  margin: 5px;
}

.footer-info {
  flex-direction: column !important;
}

.footer-contact-info p {
  font-size: min(4vw, 26px);
}

.footer-address p {
  font-size: min(3vw, 20px);
  margin: 0px !important;
}

.store-suppliers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-supplier {
  display: flex;
  justify-content: left !important;
  align-items: center;
  flex-direction: row !important;
  padding: 2%;
  width: 80%;
}

.supplier-logo-container {
  width: 30%;
}

.supplier-text-container {
  padding-left: 5%;
  width: 70%;
}