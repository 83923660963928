.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.rowItem {
  margin: 1%;
}

.Header {
  grid-column: span 12;
  /* height: 100%; */
  position: relative;
  display: flex;
  justify-content: center;  
  background-color: var(--accent-greige);
}

.second-header { 
  /* height: 300px; */
  flex-direction: column;
}

.header-picture {
  /* width: 80%; */
  height: 100%;
}

.logo-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
  width: 90%;
  padding-top: 2%;
}

.header-logo { 
  width: 60%; 
  max-width: 900px; 
} 

.second-header-logo { 
  width: min(30%, 250px);
}


.second-logo-container {
  position: absolute;
  left: 5%;
  top: 0%;
  transform: translate(0%,0%);
  z-index: 9;
  padding-top: 2%;
  display: flex;
  justify-content: start;
  margin-left: 10px;
}

.second-title-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
  padding-top: 2%;
}


.second-title-container h1 {
  font-size: 120px
}

.page-text-container, .front-page-text-container p {
  line-height: 10px;
}
.page-text-container, .front-page-text-container {
  margin-top: 10%;
}

.image-container {
  width: 100%;
  padding: 2%;
}

.image-container:hover img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.images-container {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.activity-posters {
 
}

.cafe-text {
  width: 70%;
}

.restaurant-info {
  padding-bottom: 6%;
}

iframe {
  border: none;
}